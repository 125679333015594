import context from '@truckdown/systems';
import { defineComponent, ref, Ref, onMounted } from 'vue';
import { IIconImport } from '../../IIconImport';
import './IconStringExtensions';

interface IIconData {
    icons: Ref<string[]>,
    setToSpin: Ref<boolean>
}

const getInitialData = function (): IIconData {
    return {
        icons: ref<string[]>(['fas', 'spinner']),
        setToSpin: ref<boolean>(true)
    };
}

const getIconName = function (name: string): string {
    if (name) {
        return name
            .removePrefix('fa')
            .replace(/([A-Z])/g, '-$1')
            .toLowerCase()
            .removePrefix('-');
    }
    return name;
}

const loadIcon = async function (type: string, name: string): Promise<string> {
    let service = await context.getService<IIconImport>('IIconImport');
    if (type == 'regular') {
        await service.addRegularIcon(name);
        return 'far';
    }
    else if (type == 'brands') {
        await service.addBrandsIcon(name);
        return 'fab';
    }
    else {
        await service.addSolidIcon(name);
        return 'fas';
    }
}

const mountComponent = async function (props: any, data: IIconData) {
    let service = await context.getService<IIconImport>('IIconImport');

    if (props.name) {
        let type = await loadIcon(props.type, props.name);
        data.icons.value = [type, getIconName(props.name)];
    }
    data.setToSpin.value = props.spin;
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        type: String,
        name: String,
        spin: Boolean,
        lazy: {
            type: Boolean,
            default: true
        }
    },
    setup: function (props, ctx) {
        const data = getInitialData();

        onMounted(async () => {
            await mountComponent(props, data);
        })

        return data;
    }
});
