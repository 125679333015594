let constants = {
    Roles: {
        CustomerAdminRole: 'General Admin',
        FleetUserRole: 'Fleet User',
        SystemRole: 'System Admin',
        CSRRole: 'TruckDown CSR'
    },
    Claims: {
        Name: 'name',
        Roles: 'role',
        ApiKey: 'apikey',
        LocalLogin: 'll',
        RealUser: 'run',
        ThirdPartyLogin: 'tpl',
        RequiresLocalLogin: 'rll'
    }
};
export const Constants = constants;