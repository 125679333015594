import { DirectiveBinding } from "vue";

interface ClickOutsideElement extends HTMLElement {
    clickOutsideEvent?: (this: HTMLElement, ev: MouseEvent) => void,
}

var hasDirectedElement = function (el: ClickOutsideElement): boolean {
    if (el.clickOutsideEvent) {
        return true;
    }

    if (el.parentElement !== null) {
        return hasDirectedElement(el.parentElement as ClickOutsideElement);
    }

    return false;
}

export default {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        let element = el as ClickOutsideElement;
        element.clickOutsideEvent = function (ev: Event) {
            if (!(el == ev.target || el.contains(ev.target as Node))) {
                try {
                    var target = ev.target as ClickOutsideElement;
                    if (!hasDirectedElement(target)) {
                        binding.value(ev);
                    }
                }
                catch (err: unknown) {
                    console.log('Error when clicking outside', err);
                }
            }
        }

        document.body.addEventListener('click', element.clickOutsideEvent);
    },
    unmounted(el: HTMLElement) {
        let element = el as ClickOutsideElement;
        if (element.clickOutsideEvent) {
            document.body.removeEventListener('click', element.clickOutsideEvent)
            element.clickOutsideEvent = undefined;
        }
    }
};
