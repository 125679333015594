import { defineComponent, defineEmits, ref, computed, onMounted, useSlots, defineOptions } from 'vue';
import { IIconImport } from '../../IIconImport';
import context from '@truckdown/systems';

export default defineComponent({
    inheritAttrs: false,
    props: {
        preventClose: Boolean,
        hideFooter: Boolean,
        hideClose: Boolean
    },
    emits: ['close'],
    setup: function (props, ctx) {
        const slots = useSlots();

        const showFooter = computed(() => {
            if (slots.footer) {
                return true;
            }
            return !props.hideFooter;
        });

        const showCloseIcon = computed(() => {
            if (props.preventClose) {
                return false;
            }
            return !props.hideClose;
        });

        const close = function (evt: Event) {
            if (!props.preventClose) {
                ctx.emit('close');
            }
        };

        const preventClickThrough = function (evt: Event) {
            evt.stopPropagation();
        }

        return {
            slots,
            showFooter,
            showCloseIcon,
            close,
            preventClickThrough
        };
    }
});
