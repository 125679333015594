import context, { ITaskCreator } from '@truckdown/systems';
import { defineComponent, ref, Ref, onMounted } from 'vue';
import { Constants } from '../../constants';

const isCsrOrAdmin = function () {
    if (!context.isAuthenticated) {
        return false;
    }
    return context.isInRole(Constants.Roles.SystemRole)
        || context.isInRole(Constants.Roles.CSRRole);
}

const getTasksCount = async function () {
    let service = await context.getService<ITaskCreator>('ITaskCreator');
    return await service.getCount()
        .catch((reason) => {
            console.error(reason);
            return 0;
        });
}

export default defineComponent({
    name: 'AdminTasksLink',
    setup: function (props, ctx) {
        const tasksCount = ref<number>(0);

        if (isCsrOrAdmin()) {
            onMounted(async () => {
                tasksCount.value = await getTasksCount();
            });
        }

        return {
            tasksCount
        };
    }
});


