declare interface String {
    removePrefix(prefix: string): string;
}

String.prototype.removePrefix = function (this: string, prefix: string): string {
    if (this.startsWith(prefix)) {
        return this.substring(prefix.length);
    }
    return this;
}
