(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Vue"), require("systemsApi"));
	else if(typeof define === 'function' && define.amd)
		define("systemsComponents", ["Vue", "systemsApi"], factory);
	else if(typeof exports === 'object')
		exports["systemsComponents"] = factory(require("Vue"), require("systemsApi"));
	else
		root["systemsComponents"] = factory(root["Vue"], root["systemsApi"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__2508__, __WEBPACK_EXTERNAL_MODULE__3670__) => {
return 