<template>
    <div class="recaptcha" v-bind="$attrs" :class="isLoaded ? 'clear' : ''">
        <icon v-if="!isLoaded" type="solid" name="faSpinner" aria-hidden="true" spin></icon>
        <div ref="captcha" class="g-recaptcha" style="width: 304px; height: 78px;"></div>
    </div>
    <p class="td-error" v-for="error in errorCodes">
        {{ error }}
    </p>
</template>

<script>import script from './Captcha';
    export default script;</script>

<style lang="less">
    @import "./Captcha.less";
</style>