<template>
    <div class="admin-input" :selected="searchSelected" v-bind="$attrs">
        <input name="admin-search" v-model="inputString" type="text" class="td-input admin-search" placeholder="Admin Search" @keyup.enter="searchInputKeyUp" @focus="focusSearchInput" @blur="blurSearchInput" />
        <icon @click="toggleControlPanel" class="input-icon" type="solid" name="faSearch" title="Search"></icon>
    </div>
    <transition name="modal">
        <modal v-if="showControlPanel" @close="closeControlPanel" hide-footer hide-close class="td-admin-search-modal static">
            <Suspense>
                <control-panel v-model="inputString" @close="closeControlPanel"></control-panel>
                <template #fallback>
                    <div class="loading">
                        <icon type="solid" name="faSpinner" title="Loading" spin></icon>
                    </div>
                </template>
            </Suspense>
        </modal>
    </transition>
</template>

<script>import script from './AdminSearch';
    export default script;</script>

<style lang="less">
    @import "./AdminSearch.less";
</style>

