import { defineComponent, defineExpose, Ref, ref, computed, onMounted, watch } from 'vue';
import context, { ICaptchaService } from "@truckdown/systems"; 

interface ICaptcha {
    ready: (func: () => void) => void;
    render: (el: HTMLElement | null, options: any) => string;
    reset: (id?: string) => void;
}

const loadRecaptcha = function (
    id: string,
    src: string,
    resolve: (value: void | PromiseLike<void>) => void,
    reject: (reason?: any) => void) {
    let script = document.createElement('script');
    script.id = id;
    script.setAttribute('src', src);
    script.onload = (resolve as any) as (this: GlobalEventHandlers, ev: Event) => any;
    script.onerror = (reject as any) as OnErrorEventHandler;
    document.head.appendChild(script);
}

const loadScript = function (id: string, src: string): Promise<void> {
    return new Promise<void>(function (resolve, reject) {
        let script = document.getElementById('recaptcha-script');
        if (script) {
            resolve();
        } else {
            loadRecaptcha(id, src, resolve, reject);
        }
    });
}

var loadCaptcha = async function (): Promise<ICaptcha> {
    await loadScript('recaptcha-script', 'https://www.google.com/recaptcha/api.js?render=explicit');
    return (window as any).grecaptcha as ICaptcha;
}

interface ICaptchaData {
    isLoaded: Ref<boolean>,
    captchaId: Ref<string>,
    errorCodes: Ref<string[]>,
    captcha: Ref<HTMLElement | null>,
    resetElement: boolean,
    greCaptcha?: ICaptcha,
    service?: ICaptchaService,
    element: HTMLElement | null
}

const getInitialData = function (): ICaptchaData {
    return {
        isLoaded: ref<boolean>(false),
        captchaId: ref<string>(''),
        errorCodes: ref<string[]>([]),
        captcha: ref<HTMLElement | null>(null),
        resetElement: false,
        element: null
    };
}

const getCaptchaElement = function (props: any, data: ICaptchaData): HTMLElement | null {
    let element = data.captcha.value;
    if (element == null) {
        let id = 'captcha-' + props.instance;
        element = document.getElementById(id);
        data.resetElement = true;
        if (element == null) {
            console.warn('Captcha control could not find the captcha element to render. If this is a custom element, you must include a div with the id set to "' + id + '" in the main DOM."');
        }
    }
    return element;
}

const resetElement = function (el: HTMLElement, props: any) {
    try {
        el.outerHTML = '<div id="captcha-' + props.instance + '" class="g-recaptcha"></div>';
    }
    catch {
        el.style['display'] = 'none';
    }
}

const getCaptchaCallback = function (props: any, ctx: any, data: ICaptchaData): (response: string) => void | PromiseLike<void> {
    if (props.returnResponse) {
        return (response: string) => {
            if (props.valueId && props.valueId.length > 0) {
                let formEl = document.getElementById(props.valueId as string);
                if (formEl) {
                    (formEl as HTMLInputElement).value = response;
                } else {
                    console.error('Cannot assign captcha response to valueId.  No element found.');
                }
            }
            if (data.resetElement && data.element) {
                resetElement(data.element, props);
            }
            context.events.emit('captcha-response', props.instance, response);
        };
    } else {
        return async (response: string) => {
            const res = data.service ? await data.service.processResponse(response) : undefined;
            if (res && res.success) {
                if (data.resetElement && data.element) {
                    resetElement(data.element, props);
                }
                context.events.emit('captcha-response', props.instance, response);
                ctx.emit('success');
            } else {
                data.errorCodes.value = res?.errorCodes ?? [];
                data.greCaptcha?.reset(data.captchaId.value);
            }
        };
    }

}

const setCaptchaReady = function (props: any, ctx: any, data: ICaptchaData) {
    data.greCaptcha?.ready(() => {
        data.isLoaded.value = true;
        data.captchaId.value = data.greCaptcha?.render(data.element, {
            sitekey: data.service?.captchaKey,
            callback: getCaptchaCallback(props, ctx, data)
        }) ?? '';
    });
}

const mountComponent = async function (props: any, ctx: any, data: ICaptchaData) {
    data.greCaptcha = await loadCaptcha();
    data.service = await context.getService<ICaptchaService>('ICaptchaService');

    data.element = getCaptchaElement(props, data);
    if (data.greCaptcha && data.service != null) {
        setCaptchaReady(props, ctx, data);
    }
}

export default defineComponent({
    name: 'captcha',
    props: {
        instance: String,
        returnResponse: Boolean,
        valueId: String
    },
    emits: ['success'],
    setup: function (props, ctx) {
        var data = getInitialData();

        onMounted(async () => {
            await mountComponent(props, ctx, data)
                .catch((reason) => { console.log(reason); });
        });

        return data;
    }
});

