import context from '@truckdown/systems';
import { defineComponent, ref, Ref, computed, onMounted, nextTick } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

interface IData {
    root: Ref<HTMLElement | null>,
    shouldRender: Ref<boolean>
}

const getData = function () {
    return {
        root: ref<HTMLElement | null>(null),
        shouldRender: ref<boolean>(false)
    } as IData;
}

function onIdle(cb = () => { }) {
    if ('requestIdleCallback' in window) {
        window.requestIdleCallback(cb);
    } else {
        setTimeout(() => {
            nextTick(cb);
        }, 500);
    }
}

export default defineComponent({
    name: 'lazy',
    props: {
        rootMargin: String
    },
    emits: [],
    setup: function (props, ctx) {
        let data = getData();

        const { stop } = useIntersectionObserver(
            data.root,
            ([{ isIntersecting }]) => {
                if (isIntersecting) {
                    nextTick(() => {
                        data.shouldRender.value = true;
                        stop();
                    });
                }
            },
            {
                rootMargin: props.rootMargin ?? '100px'
            }
        );

        onIdle(() => {
            data.shouldRender.value = true;
            stop();
        });

        return {
            ...data
        };
    }
});
