import { default as context } from '@truckdown/systems';

import { VueCreatorInitializer } from './VueCreatorInitializer';
import { VueMountInitializer } from './VueMountInitializer';
import { IconImport } from './IconImport';
import { IAppUserContext } from '../IAppUserContext';

let creatorInitializer = new VueCreatorInitializer();
let mountInitializer = new VueMountInitializer();

let appContext = context as IAppUserContext;
appContext.analytics = (data: any) => {
    if ((window as any).dataLayer) {
        (window as any).dataLayer.push(data)
    }
};

context.registerService({
    name: 'IIconImport',
    activator: async () => {
        return new IconImport();
    }
});
context.registerService({
    name: 'IInitializer',
    activator: async () => {
        return creatorInitializer;
    }
});
context.registerService({
    name: 'IInitializer',
    activator: async () => {
        return mountInitializer;
    }
});