<template>
    <div class="admin-tasks-link">
        <a href="/task-manager" class="task-manager" title="Task Manager">
            <icon type="solid" name="faTasks" aria-hidden="true" focusable="false" class="nav-icon" title="Task Manager"></icon>
            <span v-if="tasksCount > 0" class="td-circle-badge">{{ tasksCount }}</span>
        </a>
    </div>
    
</template>

<script>import script from './AdminTasksLink';
    export default script;</script>

<style lang="less">
    @import "./AdminTasksLink.less";
</style>
