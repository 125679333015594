import { IIconImport } from '../IIconImport';
import { IconType } from '../IconType';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core'

const loadedIcons: { name: string, type: IconType }[] = [];

export class IconImport implements IIconImport {
    private hasIcon(name: string, type: IconType): boolean {
        return loadedIcons.some(i => i.name == name && i.type == type);
    }

    async addRegularIcon(name: string): Promise<void> {
        if (name.length == 0) {
            return;
        }

        if (!this.hasIcon(name, IconType.Regular)) {
            var definition = ((await import(
                /* webpackChunkName: "icons/reg/[request]" */
                `../Icons/Regular/${name}`) as any).definition) as IconDefinition;
            library.add(definition);
            loadedIcons.push({ name: name, type: IconType.Regular });
        }
    }

    async addSolidIcon(name: string): Promise<void> {
        if (name.length == 0) {
            return;
        }

        if (!this.hasIcon(name, IconType.Solid)) {
            var definition = ((await import(
                /* webpackChunkName: "icons/sol/[request]" */
                `../Icons/Solid/${name}`) as any).definition) as IconDefinition;
            library.add(definition);
            loadedIcons.push({ name: name, type: IconType.Solid });
        }
    }

    async addBrandsIcon(name: string): Promise<void> {
        if (name.length == 0) {
            return;
        }

        if (!this.hasIcon(name, IconType.Brands)) {
            var definition = ((await import(
                /* webpackChunkName: "icons/bra/[request]" */
                `../Icons/Brands/${name}`) as any).definition) as IconDefinition;
            library.add(definition);
            loadedIcons.push({ name: name, type: IconType.Brands });
        }
    }

    registerIcon(name: string, type: IconType, definition: IconDefinition) {
        if (name.length == 0) {
            return;
        }

        if (!this.hasIcon(name, type)) {
            library.add(definition);
            loadedIcons.push({ name: name, type: type });
        }
    }
}