import context from '@truckdown/systems';
import { defineComponent, defineOptions, ref, Ref, onMounted } from 'vue';
import { IIconImport } from '../../IIconImport';

interface IAdminSearchData {
    showControlPanel: Ref<boolean>,
    searchSelected: Ref<boolean>,
    inputString: Ref<string>
}

const getInitialData = function () : IAdminSearchData {
    return {
        showControlPanel:  ref<boolean>(false),
        searchSelected:  ref<boolean>(false),
        inputString:  ref<string>('')
    }
}

const getMethods = function (data: IAdminSearchData) {
    return {
        toggleControlPanel: function (evt: Event) {
            evt.stopPropagation();
            data.showControlPanel.value = !data.showControlPanel.value;
        },

        closeControlPanel: function (evt: Event) {
            data.showControlPanel.value = false;
        },

        focusSearchInput: function (evt: Event) {
            data.searchSelected.value = true;
        },

        blurSearchInput: function (evt: Event) {
            data.searchSelected.value = false;
        },

        searchInputKeyUp: function (evt: Event) {
            if (!data.showControlPanel.value) {
                data.showControlPanel.value = true;
            }
        }
    }
}

export default defineComponent({
    name: 'AdminSearch',
    setup: function (props, ctx) {
        var data = getInitialData()
        var methods = getMethods(data);
        return {
            ...data,
            ...methods
        };
    }
});


