import context from '@truckdown/systems';
import { defineComponent, ref, onMounted, computed } from 'vue';
import { Constants } from '../../constants';
import { IIconImport } from '../../IIconImport';

let isUserAnAdmin = function (): boolean {
    if (!context.isAuthenticated) {
        return false;
    }
    return context.isInRole(Constants.Roles.SystemRole)
        || context.isInRole(Constants.Roles.CSRRole);
}

export default defineComponent({
    name: 'HeaderMenu',
    setup: function (props, ctx) {

        const isPlanRV = computed<boolean>(() => context.system == 'planrv');
        const isAuthenticated = ref<boolean>(context.isAuthenticated);
        const isAdmin = ref<boolean>(isUserAnAdmin());
        const firstName = ref<string | undefined>(context.user?.firstName);
        const lastName = ref<string | undefined>(context.user?.lastName);
        const showMenu = ref<boolean>(false);
        const hasRealUsername = ref<boolean>(
            context.hasClaim(Constants.Claims.RealUser));

        function toggleMenu(evt: Event) {
            showMenu.value = !showMenu.value;
            if (showMenu.value) {
                closeContext(evt);
                closeControlPanel(evt);
            }
        };

        function closeMenu(evt: Event) {
            showMenu.value = false;
        };

        //context info

        const showContext = ref<boolean>(false);

        function toggleContext(evt: Event) {
            showContext.value = !showContext.value;
            if (showContext.value) {
                closeMenu(evt);
                closeControlPanel(evt);
            }
        };

        function closeContext(evt: Event) {
            showContext.value = false;
        };

        //control panel

        const showControlPanel = ref<boolean>(false);

        function toggleControlPanel(evt: Event) {
            showControlPanel.value = !showControlPanel.value;
            if (showControlPanel.value) {
                closeMenu(evt);
            }
        };

        function closeControlPanel(evt: Event) {
            showControlPanel.value = false;
        };

        return {
            isAdmin,
            isAuthenticated,
            isPlanRV,
            firstName,
            lastName,
            showMenu,
            toggleMenu,
            closeMenu,
            hasRealUsername,
            showContext,
            toggleContext,
            closeContext,
            showControlPanel,
            toggleControlPanel,
            closeControlPanel
        };
    }
});


