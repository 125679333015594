import { IInitializer, IUserContext } from "@truckdown/systems";
import { App, createApp } from 'vue';
import { IAppUserContext } from "../IAppUserContext";
import clickOutsideDirective from './VueClickOutsideDirective';

export class VueCreatorInitializer implements IInitializer {
    name: string = 'VueCreatorInitializer';
    stage: number = -1;
    order: number = -9999;

    initialize(context: IUserContext): Promise<void> {
        const start = new Date().getTime();

        let app = createApp({});
        app.config.errorHandler = (error, instance, info) => {
            console.error(error, instance, info);
        }
        app.directive('click-outside', clickOutsideDirective);

        let appContext = context as IAppUserContext;
        appContext.app = app;
        context.registerService({
            name: 'App',
            activator: () => {
                return Promise.resolve(app);
            }
        });

        let elapsed = new Date().getTime() - start;
        console.debug('Vue created', elapsed);
        return Promise.resolve();
    }
}

