import { IInitializer, IUserContext } from "@truckdown/systems";
import { App } from 'vue';
import { IAppUserContext } from "../IAppUserContext";

const rootComponent: string = "rootComponent";
const defaultAppComponent: string = "#app";

export class VueMountInitializer implements IInitializer {
    name: string = 'VueMountInitializer';
    stage: number = 1;
    order: number = 9999;

    initialize(context: IUserContext): Promise<void> {
        const start = new Date().getTime();

        let appContext = context as IAppUserContext;
        var app = appContext.app;
        if (app) {
            var custom = context.settings[rootComponent];
            if (!custom || custom === null) {
                custom = defaultAppComponent;
            }
            app.mount(custom);
        } else {
            console.debug('Vue app not created.');
        }

        let elapsed = new Date().getTime() - start;
        console.debug('Vue mounted', elapsed);
        return Promise.resolve();
    }
}

